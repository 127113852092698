import React, { useEffect, useState } from "react";
import './style.css';
import { useTranslation } from "react-i18next"; 

export default function Cookies() {
    const [t] = useTranslation("global");
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        // Check if the user has previously accepted the cookies
        const acceptedCookies = localStorage.getItem("acceptedCookies");
        if (!acceptedCookies) {
            setShowBanner(true);
        }
    }, []);

    const acceptCookies = () => {
        // Set a flag in local storage to remember that the user has accepted cookies
        localStorage.setItem("acceptedCookies", "true");
        setShowBanner(false);
    };

    return showBanner ? (
        <div className="cookiesblk show">
            <div>
                <h5>{t("cookie.header")}</h5>
                <p>{t("cookie.text")}</p>
                <button type="button" onClick={acceptCookies}>{t("cookie.accept")}</button>
                <button type="button" onClick={() => setShowBanner(false)}>{t("cookie.decline")}</button>
            </div>
        </div>
    ) : null;
}