// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/imgs/grayshap2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.job-section {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    padding: 100px 0;
    margin-top: 150px;
    background-position: top;
    background-size: cover;
    position: relative;
}

section.job-section:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 30%;
    bottom: -20%;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: bottom;
    background-size: cover;
    z-index: 2;
}
.job-wrp{
    position: relative;
    z-index: 3;
}
.job-wrp > h4 {
    text-align: center;
    font-size: 30px;
    line-height: 36px;
    color: #052A42;
    margin-bottom: 100px;
}

.job-wrp ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.job-wrp ul li {
    width: 48%;
    background: #052A42;
    border-radius: 30px;
    overflow: hidden;
    margin-top: 45px;
}

.job-wrp ul li a {
    display: flex;
    justify-content: space-between;
}

.job-wrp ul li a h4 {
    padding: 40px;
    color: #fff;
    font-size: 25px;
    line-height: 30px;
    width: 100%;
    margin: 0;
}

.job-wrp ul li a img {
    display: block;
    width: 250px;
    height: 160px;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/jobs/style.css"],"names":[],"mappings":"AAAA;IACI,mDAAgD;IAChD,gBAAgB;IAChB,iBAAiB;IACjB,wBAAwB;IACxB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,YAAY;IACZ,mDAAgD;IAChD,2BAA2B;IAC3B,sBAAsB;IACtB,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,SAAS;AACb;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,aAAa;IACb,iBAAiB;AACrB","sourcesContent":["section.job-section {\n    background: url(../../assets/imgs/grayshap2.png);\n    padding: 100px 0;\n    margin-top: 150px;\n    background-position: top;\n    background-size: cover;\n    position: relative;\n}\n\nsection.job-section:after{\n    content: \"\";\n    position: absolute;\n    width: 100%;\n    height: 30%;\n    bottom: -20%;\n    background: url(../../assets/imgs/grayshap2.png);\n    background-position: bottom;\n    background-size: cover;\n    z-index: 2;\n}\n.job-wrp{\n    position: relative;\n    z-index: 3;\n}\n.job-wrp > h4 {\n    text-align: center;\n    font-size: 30px;\n    line-height: 36px;\n    color: #052A42;\n    margin-bottom: 100px;\n}\n\n.job-wrp ul {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n\n.job-wrp ul li {\n    width: 48%;\n    background: #052A42;\n    border-radius: 30px;\n    overflow: hidden;\n    margin-top: 45px;\n}\n\n.job-wrp ul li a {\n    display: flex;\n    justify-content: space-between;\n}\n\n.job-wrp ul li a h4 {\n    padding: 40px;\n    color: #fff;\n    font-size: 25px;\n    line-height: 30px;\n    width: 100%;\n    margin: 0;\n}\n\n.job-wrp ul li a img {\n    display: block;\n    width: 250px;\n    height: 160px;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
