import './style.css';
import React, { useEffect } from 'react';

export default function GoToTop() {
    // Function to scroll to the top
    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    useEffect(() => {
        // Get a reference to the button element
        const mybutton = document.getElementById("myBtn");

        // Add a scroll event listener to the window
        window.addEventListener("scroll", scrollFunction);

        function scrollFunction() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                mybutton.style.display = "block";
            } else {
                mybutton.style.display = "none";
            }
        }

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", scrollFunction);
        }
    }, []); // Empty dependency array to ensure this effect runs once

    return (
        <button onClick={topFunction} id="myBtn" title="Go to top">Top</button>
    );
}
