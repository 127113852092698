import Logo from "../../assets/imgs/logo.png" 
import { useTranslation } from "react-i18next"; 
import Fb from "../../assets/imgs/facebook.svg";
import Inst from "../../assets/imgs/instagram.svg";
import AButton from "../global/abutton";
import './style.css';

export default function Footer() {
    const [t] = useTranslation("global")
    return(
        <footer>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-top-wrp">
                                <a href="/"><img src={Logo} alt="" /></a>
                                <AButton classes={'inactive'} refs={'mailto:jobs@ypcar.be'}>{t("footer.button")}</AButton>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="footer-widget contentsEnd">
                                <p><a href="http://www.ypcar.be/index-nl.aspx">{t("footer.infloTxt")}</a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-widget links">
                                <ul>
                                    <h5>{t("footer.addr.title")}</h5>
                                    <li><a href="https://maps.app.goo.gl/wkyJomdf7ntcoZvP7">{t("footer.addr.addr")}</a></li>
                                    <li><a href="https://maps.app.goo.gl/wkyJomdf7ntcoZvP7">{t("footer.addr.addr2")}</a></li>
                                    <li><a href="https://maps.app.goo.gl/wkyJomdf7ntcoZvP7">{t("footer.addr.addr3")}</a></li>
                                </ul>
                                <ul>
                                    <h5>{t("footer.contact.title")}</h5>
                                    <li>{t("footer.contact.addr")}</li>
                                    <li><a href="mailto:jobs@ypcar.be">{t("footer.contact.addr2")}</a></li>
                                    <li>{t("footer.contact.addr3")}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 offset-lg-1 col-md-6">
                            <div className="footer-widget social">
                                <ul>
                                    <h5>{t("footer.sociallinks.title")}</h5>
                                    <li><a href="https://www.facebook.com/ypcarcabine"><img src={Fb} alt="" /> facebook</a></li>
                                    <li><a href="https://www.instagram.com/ypcarnv" className="insta"><img src={Inst} alt="" /> instagram</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="fdmnu">
                                <ul>
                                    <li>{t("footer.pp")}</li>
                                    <li>{t("footer.cp")}</li>
                                    <li>{t("footer.cprt")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
 