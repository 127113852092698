// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cookiesblk {
    position: fixed;
    top: 5%;
    left: 50%;
    z-index: 999;
    width: 70%;
    background: #000;
    transform: translateX(-50%);
    padding: 25px;
    border-radius: 10px;
    display: none;
}
.cookiesblk.show{
    display: block;
}
.cookiesblk p {
    color: #fff;
    font-size: 16px;
}

.cookiesblk button {
    display: inline-block;
    background: #556a24;
    margin-right: 15px;
    border: none;
    padding: 10px 20px;
    line-height: 16px;
    padding-bottom: 15px;
    border-radius: 2px;
    color: #fff;
    transition: .3s;
}

.cookiesblk button:hover {
    opacity: .6;
}`, "",{"version":3,"sources":["webpack://./src/components/cookies/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO;IACP,SAAS;IACT,YAAY;IACZ,UAAU;IACV,gBAAgB;IAChB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,cAAc;AAClB;AACA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".cookiesblk {\n    position: fixed;\n    top: 5%;\n    left: 50%;\n    z-index: 999;\n    width: 70%;\n    background: #000;\n    transform: translateX(-50%);\n    padding: 25px;\n    border-radius: 10px;\n    display: none;\n}\n.cookiesblk.show{\n    display: block;\n}\n.cookiesblk p {\n    color: #fff;\n    font-size: 16px;\n}\n\n.cookiesblk button {\n    display: inline-block;\n    background: #556a24;\n    margin-right: 15px;\n    border: none;\n    padding: 10px 20px;\n    line-height: 16px;\n    padding-bottom: 15px;\n    border-radius: 2px;\n    color: #fff;\n    transition: .3s;\n}\n\n.cookiesblk button:hover {\n    opacity: .6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
