// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/imgs/img4.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.werken-section {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    position: relative;
    z-index: 2;
    padding: 90px 0;
}

section.werken-section:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #052a42;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: .9;
}

section.werken-section h3.heading-title {
    color: #fff;
}

a.inactive.themeBtn {
    border-color: #ffffff;
    border-width: 2px;
    border-style: solid;
    border-radius: 54px;
    filter: drop-shadow(0px 3px 3px rgba(0,0,0,0.16 ));
    background: transparent;
}

a.inactive.themeBtn:hover {
    background: #89B414;
}
 `, "",{"version":3,"sources":["webpack://./src/components/werken/style.css"],"names":[],"mappings":"AAAA;IACI,mDAA2C;IAC3C,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,MAAM;IACN,OAAO;IACP,WAAW;IACX,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,kDAAkD;IAClD,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["section.werken-section {\n    background: url(../../assets/imgs/img4.png);\n    background-size: cover;\n    position: relative;\n    z-index: 2;\n    padding: 90px 0;\n}\n\nsection.werken-section:after {\n    content: \"\";\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    background: #052a42;\n    top: 0;\n    left: 0;\n    z-index: -1;\n    opacity: .9;\n}\n\nsection.werken-section h3.heading-title {\n    color: #fff;\n}\n\na.inactive.themeBtn {\n    border-color: #ffffff;\n    border-width: 2px;\n    border-style: solid;\n    border-radius: 54px;\n    filter: drop-shadow(0px 3px 3px rgba(0,0,0,0.16 ));\n    background: transparent;\n}\n\na.inactive.themeBtn:hover {\n    background: #89B414;\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
