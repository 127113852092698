// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/imgs/img.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/imgs/hero-overlay.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.hero-section {
    padding: 440px 0 350px 0;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    position: relative;
    background-position: bottom;
    z-index: 3;
}
section.hero-section:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-size: cover;
    background-position: bottom;
    top: 0;
    left: 0; 
    z-index: -1;
}


 

.hero-left h3 {
    color: #fff;
    font-size: 44px;
    line-height: 53px;
    max-width: 85%;
} 

.hero-right h3 {
    font-size: 30px;
    color: #fff;
    font-family: 'Lato';
    line-height: 36px;
    margin-bottom: 35px;
}

section.hero-section > a {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: inline-block;
    max-width: 100px;
}

section.hero-section > a svg {
    display: block;
    width: 100%;
}















`, "",{"version":3,"sources":["webpack://./src/components/hero/style.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,mDAA0C;IAC1C,sBAAsB;IACtB,kBAAkB;IAClB,2BAA2B;IAC3B,UAAU;AACd;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mDAAmD;IACnD,sBAAsB;IACtB,2BAA2B;IAC3B,MAAM;IACN,OAAO;IACP,WAAW;AACf;;;;;AAKA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,2BAA2B;IAC3B,UAAU;IACV,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,WAAW;AACf","sourcesContent":["section.hero-section {\n    padding: 440px 0 350px 0;\n    background: url(../../assets/imgs/img.png);\n    background-size: cover;\n    position: relative;\n    background-position: bottom;\n    z-index: 3;\n}\nsection.hero-section:after {\n    content: \"\";\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    background: url(../../assets/imgs/hero-overlay.png);\n    background-size: cover;\n    background-position: bottom;\n    top: 0;\n    left: 0; \n    z-index: -1;\n}\n\n\n \n\n.hero-left h3 {\n    color: #fff;\n    font-size: 44px;\n    line-height: 53px;\n    max-width: 85%;\n} \n\n.hero-right h3 {\n    font-size: 30px;\n    color: #fff;\n    font-family: 'Lato';\n    line-height: 36px;\n    margin-bottom: 35px;\n}\n\nsection.hero-section > a {\n    position: absolute;\n    bottom: 5%;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: 2;\n    display: inline-block;\n    max-width: 100px;\n}\n\nsection.hero-section > a svg {\n    display: block;\n    width: 100%;\n}\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
