import './style.css';
import { useTranslation } from "react-i18next"; 

export default function HeroBuyer() {
    const [t] = useTranslation("global")
    return(
        <section className="hero-details">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="hero-details-wrp">
                            <h5><a href="#jobs">{t("herobuyer.description")}</a></h5>
                            <h1>{t("herobuyer.heading")}</h1>
                        </div>
                    </div> 
                </div>
            </div> 
        </section>
    );
}
 