import Header from './components/header';
import Hero from './components/hero';
import AboutYp from './components/aboutyp';
import Team from './components/team';
import Werken from './components/werken';
import Testimonial from './components/testimonial';
import Jobs from './components/jobs';
import Cta from './components/cta';
import Footer from './components/footer';
import GoToTop from './components/gototop';
import './App.css';
import Cookies from './components/cookies';

function Home() {
  return (
    <>
      <GoToTop />
      <Cookies />
      <Header />
      <Hero />
      <AboutYp />
      <Team />
      <Werken />
      <Testimonial />
      <Jobs />
      <Cta />
      <Footer />
    </>
  );
}

export default Home;
