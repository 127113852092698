import React from "react";
import { useTranslation } from "react-i18next";
import HeadingTitle from "../global/headingtitle";
import Imgwim from "../../assets/imgs/wim.png";
import Imggregory from "../../assets/imgs/gregory.png";
import Imgjose from "../../assets/imgs/jose.png";
import Imgfrederik from "../../assets/imgs/frederik.png";
import Imgibrahim from "../../assets/imgs/ibrahim.png";
import Icon from "../../assets/imgs/play.svg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./style.css";

export default function Team() {
  const [t] = useTranslation("global");
  const [click, setClick] = React.useState(false);
  return (
    <section className="team-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <HeadingTitle title={t("team.title")} />
          </div>
          <div className="col-lg-12">
            <div className="team-slider">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                spaceBetween={50}
                slidesPerView={3}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
              >
                <SwiperSlide data-slide-id={1}>
                  <div className="teamBlk">
                    <div className="teamBlk-top">
                      <img src={Imgwim} alt="" />
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setClick(1);
                        }}
                      >
                        <img src={Icon} alt="" />
                      </a>
                    </div>
                    <div className="teamBlk-body">
                      <div className="teamBlkBody-wrp">
                        <h5>{t("team.wim.name")}</h5>
                        <b>{t("team.wim.position")}</b>
                        <p>{t("team.wim.description")}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide data-slide-id={2}>
                  <div className="teamBlk">
                    <div className="teamBlk-top">
                      <img src={Imggregory} alt="" />
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setClick(2);
                        }}
                      >
                        <img src={Icon} alt="" />
                      </a>
                    </div>
                    <div className="teamBlk-body">
                      <div className="teamBlkBody-wrp">
                        <h5>{t("team.gregory.name")}</h5>
                        <b>{t("team.gregory.position")}</b>
                        <p>{t("team.gregory.description")}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide data-slide-id={3}>
                  <div className="teamBlk">
                    <div className="teamBlk-top">
                      <img src={Imgjose} alt="" />
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setClick(3);
                        }}
                      >
                        <img src={Icon} alt="" />
                      </a>
                    </div>
                    <div className="teamBlk-body">
                      <div className="teamBlkBody-wrp">
                        <h5>{t("team.jose.name")}</h5>
                        <b>{t("team.jose.position")}</b>
                        <p>{t("team.jose.description")}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide data-slide-id={4}>
                  <div className="teamBlk">
                    <div className="teamBlk-top">
                      <img src={Imgfrederik} alt="" />
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setClick(4);
                        }}
                      >
                        <img src={Icon} alt="" />
                      </a>
                    </div>
                    <div className="teamBlk-body">
                      <div className="teamBlkBody-wrp">
                        <h5>{t("team.frederik.name")}</h5>
                        <b>{t("team.frederik.position")}</b>
                        <p>{t("team.frederik.description")}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide data-slide-id={5}>
                  <div className="teamBlk">
                    <div className="teamBlk-top">
                      <img src={Imgibrahim} alt="" />
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setClick(5);
                        }}
                      >
                        <img src={Icon} alt="" />
                      </a>
                    </div>
                    <div className="teamBlk-body">
                      <div className="teamBlkBody-wrp">
                        <h5>{t("team.ibrahim.name")}</h5>
                        <b>{t("team.ibrahim.position")}</b>
                        <p>{t("team.ibrahim.description")}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className={click ? "videoPopup show" : "videoPopup"}>
        <div>
          <button type="button" onClick={() => setClick(0)}>
            X
          </button>
          {click === 1 && (
            <iframe
              height="506"
              src={t("team.wim.video")}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
          {click === 2 && (
            <iframe
              height="506"
              src={t("team.gregory.video")}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
          {click === 3 && (
            <iframe
              width="900"
              height="506"
              src={t("team.jose.video")}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
          {click === 4 && (
            <iframe
              height="506"
              src={t("team.frederik.video")}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </div>
    </section>
  );
}
