// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/imgs/cta-overlay.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.cta-section {
    position: relative;
    z-index: -1;
    padding: 400px 0;
    margin-bottom: -160px;
}

section.cta-section > img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section.cta-section:after {
    content: "";position: absolute;width: 100%;height: 100%;background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});top: 0;left: 0;z-index: -1;
    background-size: cover;
    background-position: center;
}

.cta-wrp {
    text-align: center;
}

.cta-wrp img {
    height: 100px;
    display: block;
    margin: auto;
}

.cta-wrp h4 {
    margin: 0;
    margin-top: 50px;
    color: #fff;
    font-size: 44px;
    line-height: 53px;
}`, "",{"version":3,"sources":["webpack://./src/components/cta/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,WAAW,CAAC,kBAAkB,CAAC,WAAW,CAAC,YAAY,CAAC,mDAAkD,CAAC,MAAM,CAAC,OAAO,CAAC,WAAW;IACrI,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,iBAAiB;AACrB","sourcesContent":["section.cta-section {\n    position: relative;\n    z-index: -1;\n    padding: 400px 0;\n    margin-bottom: -160px;\n}\n\nsection.cta-section > img {\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: -1;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\nsection.cta-section:after {\n    content: \"\";position: absolute;width: 100%;height: 100%;background: url(../../assets/imgs/cta-overlay.png);top: 0;left: 0;z-index: -1;\n    background-size: cover;\n    background-position: center;\n}\n\n.cta-wrp {\n    text-align: center;\n}\n\n.cta-wrp img {\n    height: 100px;\n    display: block;\n    margin: auto;\n}\n\n.cta-wrp h4 {\n    margin: 0;\n    margin-top: 50px;\n    color: #fff;\n    font-size: 44px;\n    line-height: 53px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
