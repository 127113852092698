import './style.css';
import { useTranslation } from "react-i18next";

export default function HeroWerkvoorbereider() {
    const [t] = useTranslation("global")
    return (
        <section className="hero-details">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="hero-details-wrp">
                            <h5><a href="#jobs">{t("herowerkvoorbereider.description")}</a></h5>
                            <h1>{t("herowerkvoorbereider.heading")}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
