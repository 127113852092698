import Logo from "../../assets/imgs/logo2.png"
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';
import './style.css';



export default function VacatureLasser() {
    const [t] = useTranslation("global")
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        const fnameInput = form.current.fname;
        const lnameInput = form.current.lname;
        const emailInput = form.current.email;
        const messageInput = form.current.message;

        if (
            !fnameInput.value.trim() ||
            !lnameInput.value.trim() ||
            !emailInput.value.trim() ||
            !messageInput.value.trim()
        ) {
            // Display an error message or prevent submission
            alert(t("vacaturespontaan.email.errortext"));
            return;
        }
        emailjs
            .sendForm(
                "service_gt5sdbi",
                "Vacature_Sender",
                form.current,
                "9K5-G_t8uYCJcIbPp"
            )
            .then(
                (result) => {
                    alert(t("vacaturespontaan.email.send"));
                    window.location.reload();
                    console.log(result.text);
                },
                (error) => {
                    alert(t("vacaturespontaan.email.errorsend"));
                    console.log(error.text);
                }
            );
    };
    return (
        <section className="DetailsCta-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="DetailsCta-wrp">
                            <p>
                                <b>{t("vacaturespontaan.contents.pr")}</b>
                            </p>
                            <h5>{t("vacaturespontaan.contents.whatsgoing.heading")}</h5>
                            <p>{t("vacaturespontaan.contents.whatsgoing.pr")}</p>
                            <h5>{t("vacaturespontaan.contents.whoareyou.heading")}</h5>
                            <ul>
                                <li>{t("vacaturespontaan.contents.whoareyou.pr")}</li>
                                <li>{t("vacaturespontaan.contents.whoareyou.pr2")}</li>
                                <li>{t("vacaturespontaan.contents.whoareyou.pr3")}</li>
                                <li>{t("vacaturespontaan.contents.whoareyou.pr4")}</li>
                                <li>{t("vacaturespontaan.contents.whoareyou.pr5")}</li>
                                <li>{t("vacaturespontaan.contents.whoareyou.pr6")}</li>
                            </ul>
                            <h5>{t("vacaturespontaan.contents.whatoexpt.heading")}</h5>
                            <ul>
                                <li>{t("vacaturespontaan.contents.whatoexpt.pr")}</li>
                                <li>{t("vacaturespontaan.contents.whatoexpt.pr2")}</li>
                                <li>{t("vacaturespontaan.contents.whatoexpt.pr3")}</li>
                                <li>{t("vacaturespontaan.contents.whatoexpt.pr4")}</li>
                                <li>{t("vacaturespontaan.contents.whatoexpt.pr5")}</li>
                                <li>{t("vacaturespontaan.contents.whatoexpt.pr6")}</li>
                                <li>{t("vacaturespontaan.contents.whatoexpt.pr7")}</li>
                            </ul>
                            <p><b>{t("vacaturespontaan.contents.pr2")}</b></p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="DetailsCta-wrp-form">
                            <form className='cf' ref={form} onSubmit={sendEmail}>
                                <img src={Logo} alt="" />
                                <h5>{t("vacaturespontaan.forms.heading")}</h5>
                                <h6>{t("vacaturespontaan.forms.description")}</h6>
                                <label htmlFor="#">
                                    <input type="text" value={t("vacaturespontaan.forms.placeholders.job")} name="job" />
                                </label>
                                <label htmlFor="#">
                                    <input type="text" placeholder={t("vacaturespontaan.forms.placeholders.fname")} name="fname" />
                                </label>
                                <label htmlFor="#">
                                    <input type="text" placeholder={t("vacaturespontaan.forms.placeholders.lname")} name="lname" />
                                </label>
                                <label htmlFor="#">
                                    <input type="text" placeholder={t("vacaturespontaan.forms.placeholders.email")} name="email" />
                                </label>
                                <label htmlFor="#">
                                    <textarea placeholder={t("vacaturespontaan.forms.placeholders.message")} name="message"></textarea>
                                </label>
                                <button type='submit' value='Submit' id='input-submit'>{t("vacaturespontaan.forms.button")}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
