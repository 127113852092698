import Header from './components/header'; 
import HeroEngineer from './components/hero-engineer';
import VacatureEngineer from './components/vacatureengineer';
import AboutYp from './components/aboutyp';
import Team from './components/team';
import Werken from './components/werken';
import Testimonial from './components/testimonial';
import Jobs from './components/jobs';
import Cta from './components/cta';
import Footer from './components/footer';
import GoToTop from './components/gototop';

function Engineer() {
  return (
    <div className="App">
      <GoToTop />
      <Header />
      <HeroEngineer />
      <VacatureEngineer />
      <AboutYp classes={'withanothershape'} />
      <Team />
      <Werken />
      <Testimonial />
      <Jobs />
      <Cta />
      <Footer />
    </div>
  );
}

export default Engineer;
