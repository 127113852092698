import { Children } from "react";
import './style.css';

export default function Attr(props) {
    return(
        <a href={props.refs ? props.refs : "#"} className={props.classes ? props.classes : ""}> 
            {props.children}
        </a>
    );
}
