// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#myBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: red; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 18px; /* Increase font size */
  }
  
  #myBtn:hover {
    background-color: #555; /* Add a dark-grey background on hover */
  }`, "",{"version":3,"sources":["webpack://./src/components/gototop/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa,EAAE,sBAAsB;IACrC,eAAe,EAAE,0BAA0B;IAC3C,YAAY,EAAE,+CAA+C;IAC7D,WAAW,EAAE,yCAAyC;IACtD,WAAW,EAAE,kCAAkC;IAC/C,YAAY,EAAE,mBAAmB;IACjC,aAAa,EAAE,mBAAmB;IAClC,qBAAqB,EAAE,2BAA2B;IAClD,YAAY,EAAE,eAAe;IAC7B,eAAe,EAAE,iCAAiC;IAClD,aAAa,EAAE,iBAAiB;IAChC,mBAAmB,EAAE,oBAAoB;IACzC,eAAe,EAAE,uBAAuB;EAC1C;;EAEA;IACE,sBAAsB,EAAE,wCAAwC;EAClE","sourcesContent":["#myBtn {\n    display: none; /* Hidden by default */\n    position: fixed; /* Fixed/sticky position */\n    bottom: 20px; /* Place the button at the bottom of the page */\n    right: 30px; /* Place the button 30px from the right */\n    z-index: 99; /* Make sure it does not overlap */\n    border: none; /* Remove borders */\n    outline: none; /* Remove outline */\n    background-color: red; /* Set a background color */\n    color: white; /* Text color */\n    cursor: pointer; /* Add a mouse pointer on hover */\n    padding: 15px; /* Some padding */\n    border-radius: 10px; /* Rounded corners */\n    font-size: 18px; /* Increase font size */\n  }\n  \n  #myBtn:hover {\n    background-color: #555; /* Add a dark-grey background on hover */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
