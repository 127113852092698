import HeadingTitle from "../global/headingtitle";
import { useTranslation } from "react-i18next"; 
import YpAbout from "../global/ypabout";
import Img from "../../assets/imgs/img2.png";
import Img2 from "../../assets/imgs/img3.jpg";
import Icon from "../../assets/imgs/star.svg";
import Icon2 from "../../assets/imgs/location.svg";
import './style.css';


export default function AboutYp(props) {
    const [t, i18n] = useTranslation("global")
    return(
        <section className={props.classes ? props.classes+" aboutYp" : "aboutYp"} id="aboutYp">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12"> 
                        <HeadingTitle title={t("aboutyp.title")}/>
                    </div>
                    <div className="col-lg-6">
                        <YpAbout img={Img} icon={Icon} heading={t("aboutyp.aboutBlk.heading")}>
                        {t("aboutyp.aboutBlk.content")} 
                        </YpAbout>
                    </div>
                    <div className="col-lg-6">
                        <YpAbout img={Img2} icon={Icon2} heading={t("aboutyp.aboutBlk2.heading")}>
                            {t("aboutyp.aboutBlk2.content")} 
                        </YpAbout>
                    </div>
                </div>
            </div>
        </section>
    );
}