import './style.css';
export default function YpAbout(props) {
    return(
        <div className="ypAboutBlk">
            <div className="ypAboutBlk-top">
                <img src={props.img} alt="" />
                <div className="icon"><img src={props.icon} alt="" /></div>
                <h4>{props.heading}</h4>
            </div>
            <div className="ypAboutBlk-body">
                { props.children }
            </div>
        </div>
    );
}