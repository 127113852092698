// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/imgs/details-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.hero-details {
    padding: 150px 0 50px 0;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover; 
    position: relative;
    background-position: bottom;
    z-index: 3;
}
section.hero-details:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #052A42CB 0%, #89B414 100%) 0% 0% no-repeat padding-box;
    opacity: 0.65;
    top: 0;
    left: 0; 
    z-index: -1;
} 

.hero-details-wrp h5 {
    letter-spacing: 0.1px;
    color: #FFFFFF;
    font-weight: 700;
    font-family: 'Lato';
    font-size: 20px;
    margin-bottom: 20px;
}

.hero-details-wrp h1 {
    margin: 0;
    color: #fff;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    max-width: 545px;
}











  `, "",{"version":3,"sources":["webpack://./src/components/hero-lasser/style.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mDAAiD;IACjD,sBAAsB;IACtB,kBAAkB;IAClB,2BAA2B;IAC3B,UAAU;AACd;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,uGAAuG;IACvG,aAAa;IACb,MAAM;IACN,OAAO;IACP,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":["section.hero-details {\n    padding: 150px 0 50px 0;\n    background: url(../../assets/imgs/details-bg.png);\n    background-size: cover; \n    position: relative;\n    background-position: bottom;\n    z-index: 3;\n}\nsection.hero-details:after {\n    content: \"\";\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    background: transparent linear-gradient(180deg, #052A42CB 0%, #89B414 100%) 0% 0% no-repeat padding-box;\n    opacity: 0.65;\n    top: 0;\n    left: 0; \n    z-index: -1;\n} \n\n.hero-details-wrp h5 {\n    letter-spacing: 0.1px;\n    color: #FFFFFF;\n    font-weight: 700;\n    font-family: 'Lato';\n    font-size: 20px;\n    margin-bottom: 20px;\n}\n\n.hero-details-wrp h1 {\n    margin: 0;\n    color: #fff;\n    font-weight: 600;\n    font-size: 50px;\n    line-height: 60px;\n    max-width: 545px;\n}\n\n\n\n\n\n\n\n\n\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
