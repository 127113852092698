import HeadingTitle from "../global/headingtitle";
import { useTranslation } from "react-i18next"; 
import Icon from "../../assets/imgs/quote.png";
import Img from "../../assets/imgs/img5.png";
import Logo from "../../assets/imgs/logo.png";
import './style.css';


export default function Testimonial() {
    const [t] = useTranslation("global")
    return(
        <section className="testimonial-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="testimonial-blk">
                            <div className="testwrp">
                                <img src={Icon} alt="" />
                                <p>{t("testimonial.description")}</p>
                                <h5>
                                    {t("testimonial.name")}
                                    <span>{t("testimonial.position")}</span>
                                </h5>
                            </div>
                            <div className="testimgs">
                                <img src={Img} alt="" />
                                <img className="logo" src={Logo} alt="" />
                            </div>
                        </div>
                    </div> 
                    <div className="col-lg-6 offset-lg-3"> 
                        <div className="heading-how">
                            <HeadingTitle title={t("jobs.heading")}/>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    );
}
 