import Job from "../global/job";
import { useTranslation } from "react-i18next"; 
import Img from "../../assets/imgs/img6.png";
import Img2 from "../../assets/imgs/img7.png";
import Img3 from "../../assets/imgs/img8.png";
import Img4 from "../../assets/imgs/img9.png";
import Img5 from "../../assets/imgs/img10.png";
import Img6 from "../../assets/imgs/img11.png";
import './style.css';
// <li><Job title={t("jobs.jobitem.title")} href={'lasser'} src={Img} /></li>
// <li><Job title={t("jobs.jobitem6.title")} href={'spontaan'} src={Img6} /></li>
export default function Jobs() {
    const [t] = useTranslation("global")
    return(
        <section className="job-section" id="jobs">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="job-wrp">
                            <h4>{t("jobs.heading2")}</h4>
                            <ul>
                                <li><Job title={t("jobs.jobitem2.title")} href={'werkvoorbereider'} src={Img2} /></li>
                                <li><Job title={t("jobs.jobitem3.title")} href={'monteur'} src={Img3} /></li>
                                <li><Job title={t("jobs.jobitem4.title")} href={'buyer'} src={Img4} /></li>
                                <li><Job title={t("jobs.jobitem5.title")} href={'engineer'} src={Img5} /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    );
}
 