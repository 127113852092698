 
import { useTranslation } from "react-i18next"; 
import AButton from "../global/abutton";
import Attr from "../global/attr";
import './style.css';

export default function Hero() {
 
    const [t] = useTranslation("global")
 


    return(
        <section className="hero-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="hero-left">
                            <h3>{t("hero.heading")}</h3>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="hero-right">
                            <h3>{t("hero.heading2")} </h3>
                            <AButton refs={'#jobs'}>{t("hero.button")}</AButton>
                        </div>
                    </div>
                </div>
            </div>
            <Attr refs={'#aboutYp'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="108.86" height="36.384" viewBox="0 0 108.86 36.384">
                    <path id="Path_27" data-name="Path 27" d="M108.86,0,54.43,31.758,0,0V4.627L54.43,36.384,108.86,4.627Z" transform="translate(0 0)" fill="#aece39"/>
                </svg>
            </Attr>
        </section>
    );
}
 