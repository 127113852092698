import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Lasser from './Vacature-Lasser';
import Monteur from './Vacature-Monteur';
import Engineer from './Vacature-Engineer';
import Werkvoorbereider from './Vacature-Werkvoorbereider';
import Buyer from './Vacature-Buyer';
import Spontaan from './Vacature-Spontaan';
import "./App.css";

import global_en from "./translations/en/global.json";
import global_nl from "./translations/nl/global.json";
import global_fr from "./translations/fr/global.json";

import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import Home from "./home";

import TagManager from 'react-gtm-module'
const tagManagerArgs = {
    gtmId: 'GT-K546PRP'
};

TagManager.initialize(tagManagerArgs);

i18next.init({
  interpolation: { escapeValue: false },
  lng: "nl",
  resources: {
    en: {
      global: global_en,
    },
    nl: {
      global: global_nl,
    },
    fr: {
      global: global_fr,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <Routes>
          <Route path="lasser" element={<Lasser />} />
          <Route path="monteur" element={<Monteur />} />
          <Route path="engineer" element={<Engineer />} />
          <Route path="werkvoorbereider" element={<Werkvoorbereider />} />
          <Route path="buyer" element={<Buyer />} />
          <Route path="spontaan" element={<Spontaan />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);
