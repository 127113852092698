import Logo from "../../assets/imgs/logo.png" 
import { useTranslation } from "react-i18next"; 
import Img from "../../assets/imgs/img2.png";
import './style.css';

export default function Cta() {
    const [t, i18n] = useTranslation("global")
    return(
        <section className="cta-section">
            <img src={Img} alt="" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 text-center">
                        <div className="cta-wrp">
                            <img src={Logo} alt="" />
                            <h4>{t("cta.heading")}</h4> 
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    );
}
 