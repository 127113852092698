import AButton from "../global/abutton"; 
import { useTranslation } from "react-i18next"; 
import HeadingTitle from "../global/headingtitle"; 
import WerkenBlk from "../global/werkenblk";
import './style.css';


export default function Werken() {
    const [t] = useTranslation("global")
    return(
        <section className="werken-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <HeadingTitle title={t("werken.title")}/>
                    </div>
                    <div className="col-lg-4">
                        <WerkenBlk heading={t("werken.werkenBlk.heading")}>
                            <p>{t("werken.werkenBlk.description")}</p>
                        </WerkenBlk>
                    </div> 
                    <div className="col-lg-4">
                        <WerkenBlk heading={t("werken.werkenBlk2.heading")}>
                            <p>{t("werken.werkenBlk2.description")}</p>
                        </WerkenBlk>
                    </div> 
                    <div className="col-lg-4">
                        <WerkenBlk heading={t("werken.werkenBlk3.heading")}>
                            <p>{t("werken.werkenBlk3.description")}</p>
                        </WerkenBlk>
                    </div> 
                    <div className="col-lg-12 text-center">
                        <AButton classes={'inactive'} refs={'#jobs'}>{t("werken.button")}</AButton>
                    </div>
                </div>
            </div> 
        </section>
    );
}
 