import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/imgs/logo.png"
import './style.css';



function Header() {
    const [lng, setLng] = useState(() => {
        // Initialize the selected language from localStorage, or use "nl" as the default language.
        const storedLang = localStorage.getItem("selectedLanguage");
        return storedLang || "nl";
    });
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const [t, i18n] = useTranslation("global");

    const HandleLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLng(lang);
        // Store the selected language in localStorage.
        localStorage.setItem("selectedLanguage", lang);
    };

    useEffect(() => {
        // Update the language when the component mounts.
        i18n.changeLanguage(lng);
    }, [lng, i18n]);

    return (
        <header>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-3 col-6">
                        <div className="logo"><a href="/"><img src={Logo} alt="" /></a></div>

                    </div>
                    <div className="col-md-9 text-end col-6">
                        <div className="header-menu">
                            <button className="d-block d-md-none" type="button" onClick={handleClick}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                </svg>
                            </button>
                            <ul className="d-none d-md-flex">
                                <li><a href="#jobs">{t("header.menu")} <span>4</span></a></li>
                                <li><a href="#aboutYp">{t("header.menu2")}</a></li>
                                <li>
                                    <div className="lng-dropDown">
                                        <span>
                                            {lng}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21.698" height="8.593" viewBox="0 0 21.698 8.593">
                                                <path id="Path_28" data-name="Path 28" d="M21.7,0,10.849,5.463,0,0V3.13L10.849,8.593,21.7,3.13Z" transform="translate(0 0)" fill="#aece39" />
                                            </svg>
                                        </span>
                                        <div className="lists">
                                            <button type="button" className={lng === "nl" ? 'd-none' : ""} onClick={() => {
                                                setLng('nl')
                                                HandleLanguage('nl')
                                            }}>NL</button>
                                            <button type="button" className={lng === "en" ? 'd-none' : ""} onClick={() => {
                                                setLng('en')
                                                HandleLanguage('en')
                                            }}>EN</button>
                                            <button type="button" className={lng === "fr" ? 'd-none' : ""} onClick={() => {
                                                setLng('fr')
                                                HandleLanguage('fr')
                                            }}>FR</button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={click ? "siteBar mobile-menu header-menu" : "mobile-menu header-menu"}>
                <button className="bars" type="button" onClick={handleClick}>X</button>
                <ul className="d-block">
                    <li>
                        <select name="#" onChange={(e) => HandleLanguage(e.target.value)}>
                            <option value="nl">NL</option>
                            <option value="en">EN</option>
                            <option value="fr">FR</option>
                        </select>
                    </li>
                    <li><a href="#jobs">{t("header.menu")} <span>5</span></a></li>
                    <li><a href="#aboutYp">{t("header.menu2")}</a></li>
                </ul>
            </div>
        </header>
    );
}

export default Header;