import Header from './components/header'; 
import HeroLasser from './components/hero-lasser';
import VacatureLasser from './components/vacaturelasser';
import AboutYp from './components/aboutyp';
import Team from './components/team';
import Werken from './components/werken';
import Testimonial from './components/testimonial';
import Jobs from './components/jobs';
import Cta from './components/cta';
import Footer from './components/footer';
import GoToTop from './components/gototop';

function Lasser() {
  return (
    <div className="App">
      <GoToTop />
      <Header />
      <HeroLasser />
      <VacatureLasser />
      <AboutYp classes={'withanothershape'} />
      <Team />
      <Werken />
      <Testimonial />
      <Jobs />
      <Cta />
      <Footer />
    </div>
  );
}

export default Lasser;
